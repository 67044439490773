import React from 'react';
import { reduxForm } from 'redux-form'
import { Button, Dialog, Input, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb'

import { NewSelect } from 'ucode-components';
import { connect } from 'react-redux';

@connect(
    (state) => {
        return {
            form: state.form.templateForm,
            promotionById: state.promotions.byId,
        }
    },
    {

    }



)
class ClearModal0 extends React.Component {
    constructor(props) {
        super(props);
    }

    validateInput = (value, callback) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '账号名称不能为空';
        }
        else if (value2.trim().length > 30) {
            return '账号名称最多30个字符';
        }
    }

    componentDidMount() {

    }


    //保存修改
    modalOk = (value) => {
        const { handleClearOk } = this.props;
        handleClearOk(value);
    };
    //取消修改
    modelCancel = () => {
        const { handleclearCancel } = this.props;
        handleclearCancel();
    }

    render() {
        const { clearDialog, handleSubmit, form, promotionById, change } = this.props;
        return (
            <div>
                <Dialog
                    style={{ width: 375, minWidth: 375 }}
                    title='解锁账号'
                    visible={clearDialog}
                    okText="确认"
                    cancelText="取消"
                    onOk={handleSubmit(this.modalOk)}
                    onCancel={() => { this.modelCancel(false) }}
                    shouldCloseOnOverlayClick={false}>

                    <FormGroupList >
                        <FormGroupField
                            label='账号名称'
                            name="name"
                            component={Input}
                            type="text"
                            placeholder="请输入"
                            style={{ width: 228 }}
                            validate={this.validateInput}
                        />

                    </FormGroupList>

                </Dialog>


            </div >
        )
    }
}
const ClearModal = reduxForm({
    form: 'clearForm',
})(ClearModal0);

export default ClearModal;