import React from 'react';
import { connect } from 'react-redux';

import { Button, SearchInput, DataTable, Pager, Dialog, Bubble, notification } from 'tyb';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { loadCodePrintingConfigList, loadCodeParamsDetail, setCurrentStep, setEditStep, setCreateStep } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { loadEnterprisesList, ableEnterprise } = iceStarkData.store.get('commonAction')?.enterprises
const { getRoleSelections } = iceStarkData.store.get('commonAction')?.role;
const { getUserData, unlock } = iceStarkData.store.get('commonAction')?.users;



import { handSortOrder } from 'ucode-utils';
import { formatDate } from 'ucode-utils';

import ViewDialog from './components/ViewDialog';
import ClearModal from './components/ClearModal';
import './index.scss';



@connect(
  state => ({
    loading: state.enterprises.loadEnterprisesListLoading,
    enterprisesList: state.enterprises.enterprisesList,
    openIds: state.users.openIds,


    // codeParamsDetail: state.codePrintingConfig.codeParamsDetail,
    // codeParamsDetailDialogStatus: state.ui.codeParamsDetailDialogStatus,
    permissionIds: state.users.permissionIds || [],

  }),
  {
    loadEnterprisesList: loadEnterprisesList.REQUEST,
    ableEnterprise: ableEnterprise.REQUEST,
    getRoleSelections: getRoleSelections.REQUEST,
    getUserData: getUserData.REQUEST,
    unlock: unlock.REQUEST,
    setNavBar,
    // loadCodeParamsDetail: loadCodeParamsDetail.REQUEST,
    // setCurrentStep,
    // setEditStep,
    // setCreateStep
  }
)
class CodeTempConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: this.getTabColumns(),
      perPageCountList: [20, 50, 100],
      sortIdOrder: null,
      params: {
        name: '',
        page: 0,
        size: 20,
        sort: 'id,desc'
      },
      viewDialogStatus: false,
      viewObject: {},

      clearDialog: false,
    }
  }

  componentDidMount() {
    this.props.loadEnterprisesList({});
    setTimeout(() => {
      this.props.getRoleSelections('ENTERPRISE');
    }, 1);

  }

  changeSearchBox = (searchText) => {
    const params = this.state.params;
    params.name = searchText
    this.setState({
      params
    })
  }

  search = () => {
    const params = this.state.params;
    params.page = 0;
    this.props.loadEnterprisesList(params);
  }
  handleClear = () => {
    this.setState({
      clearDialog: true,
    })
  }

  handleclearCancel = () => {
    this.setState({
      clearDialog: false,
    })
  }
  handleClearOk = (value) => {
    this.props.unlock({
      data: value.name, callback: () => {
        notification.success('解锁成功')
        this.setState({
          clearDialog: false,
        })
      }
    })

  }

  handleCreate = () => {
    this.setState({
      viewDialogStatus: 1,
      viewObject: {},
    })
  }
  handleEdit = (record) => {
    this.setState({
      viewDialogStatus: 2,
      viewObject: record
    })
  }
  handleAble = (record, type) => {
    if (type == 'disable') {


      this.props.ableEnterprise(
        {
          data: {
            id: record.id,
            status: 'DISABLE',
          },
          callback: () => {

            this.handleCancel();
            this.props.loadEnterprisesList(this.state.params)
          }
        }
      )


    }
    else if (type == 'enable') {
      this.props.ableEnterprise(
        {
          data: {
            id: record.id,
            status: 'ENABLE',
          },
          callback: () => {

            this.handleCancel();
            this.props.loadEnterprisesList(this.state.params)
          }
        }
      )

    }
  }
  handleAllot = (record) => {
    this.props.getUserData(
      {
        id: record.masterId,
        callback: (res) => {
          // console.log(res)
          this.setState({
            viewDialogStatus: 3,
            viewObject: { ...record, roleIds: res.roleIds }
          })
        }
      }
    )

  }
  handleInvite = (record) => {
    this.setState({
      viewDialogStatus: 4,
      viewObject: record
    })
  }
  handleAllotOut = (record) => {

    this.props.history.push(`/dataenterprise/allot?id=${record.id}&name=${record.name}&type=0`);
  }


  handleAllotTime = (record) => {
    this.props.setNavBar('过期设置');
    this.props.history.push(`/dataenterprise/timeConfig?id=${record.id}&name=${record.name}&type=0`);
  }


  getTabColumns = () => {
    const { permissionIds } = this.props;
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: '企业ID',
        // render: (value, record) => <div>
        //   <div title={record.name}><span className="text-overflow">{record.name}</span></div>
        //   <div title={value}><span className="text-overflow">{value}</span></div>
        // </div>
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: '企业名称',
      },
      {
        key: 'contacts',
        dataIndex: 'contacts',
        title: '联系人',
        // render: (value) => <div title={this.state.passwordComplexity[value]}>
        //   <span className="text-overflow">{this.state.passwordComplexity[value]}</span>
        // </div>
      },
      {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: '创建时间',
        render: (value, record) => {
          return <div title={value ? formatDate(value) : ''}>
            <span className="text-overflow">{value ? formatDate(value) : ''}</span>
          </div>
        }
      },
      // {
      //   key: 'updatedTime',
      //   dataIndex: 'updatedTime',
      //   title: '最后修改时间',
      //   render: (value) => <div title={formatDate(value)}>
      //     <span className="text-overflow">{formatDate(value)}</span>
      //   </div>
      // },
      {
        key: 'op',
        dataIndex: 'op',
        title: '操作',
        render: (value, record) =>
          <div className="table-operate-btn">
            {

              permissionIds.includes('enterprise.save') &&
              <span onClick={() => {
                this.handleEdit(record)
              }}>编辑</span>
            }
            {(record.status == 'ENABLE' && permissionIds.includes('enterprise.status')) &&
              <span onClick={() => {
                this.handleAble(record, 'disable')
              }}>禁用</span>
            }
            {(record.status == 'DISABLE' && permissionIds.includes('enterprise.status')) &&
              <span onClick={() => {
                this.handleAble(record, 'enable')
              }}>启用</span>
            }
            {/* {
              // permissionIds.includes('ws.tempConfig.edit') &&
              <span onClick={() => {
                this.handleInvite(record)
              }}>邀请码</span>
            } */}
            {
              permissionIds.includes('enterprise.config') &&
              <span onClick={() => {
                this.handleAllotOut(record)
              }}>资源分配</span>
            }
            {
              permissionIds.includes('enterprise.permission') &&
              <span onClick={() => {
                this.handleAllot(record)
              }}>权限分配</span>
            }
            {
              permissionIds.includes('enterprise.expirationTimeRecord') &&
              <span onClick={() => {
                this.handleAllotTime(record)
              }}>过期设置</span>
            }
          </div>
      },
    ]
  }

  getColumns = () => {
    return this.getTabColumns().map(column => {
      if (column.key === 'id') {
        return {
          ...column,
          headerRender: this.renderIdSortHeader
        }
      } else {
        return column;
      }
    });
  }

  handleSortId = () => {
    const newOrder = handSortOrder(this.state.sortIdOrder);
    const params = this.state.params;
    const sort = `id,${newOrder}`;
    params.sort = sort;
    this.setState({ params, sortIdOrder: newOrder });
    this.props.loadEnterprisesList(params);
  }

  renderIdSortHeader = (col) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state.sortIdOrder}
        onSort={this.handleSortId}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    )
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loadEnterprisesList(params);
  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadEnterprisesList(params);
  }

  handleCancel = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {},
    })
  }

  render() {
    const { params, viewObject, viewDialogStatus, perPageCountList, clearDialog } = this.state;
    const { enterprisesList = {}, loading, permissionIds } = this.props;
    const enterprisesListLength = enterprisesList.content ? enterprisesList.content.length : 0
    return (
      <div className={`enterprises`}>

        <div className="search-bar">
          <div className="tc-15-bubble-icon">
            {
              permissionIds.includes('enterprise.save') &&
              <Button style={{ marginRight: 10 }} onClick={this.handleCreate} >创建企业</Button>
            }
            {
              permissionIds.includes('data.organization.user.unlock') &&
              <Button className="weak" onClick={this.handleClear} >解锁账号</Button>
            }
            {/* { codePrintingConfigList.totalElements >= 20 && <Bubble position="bottom">只可建20个码印刷规格</Bubble> } */}
          </div>

          <div className="search-input-block">
            <SearchInput
              onChange={this.changeSearchBox}
              onSearch={this.search}
              value={params.name}
              placeholder="输入企业名称搜索"
            />
          </div>
        </div>
        <DataTable
          // bodyStyle={{ width: '100%', maxWidth: '100%' }}
          columns={this.getColumns()}
          data={enterprisesList.content}
          rowKey="id"
          loading={loading}
          rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) {
              return <span className="text-weak">暂无企业
              {permissionIds.includes('enterprise.save') && <React.Fragment>，您可以点击左上角<span onClick={this.handleCreate}>创建企业</span></React.Fragment>}
              </span>
            }
          }}
        />

        {enterprisesListLength > 0 && !loading ? <div className="page-padding20">
          <Pager
            total={enterprisesList.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />
        </div> : ''}

        {viewDialogStatus && <ViewDialog visible={viewDialogStatus} viewObject={viewObject} handleCancel={this.handleCancel} params={params} />}

        {clearDialog && <ClearModal clearDialog={clearDialog} handleClearOk={this.handleClearOk} handleclearCancel={this.handleclearCancel} />}
      </div>
    )
  }
}

export default CodeTempConfig;